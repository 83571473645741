import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '@rxap/config';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { KeycloakService } from '@rxap/keycloak';
import { StatusIndicatorComponent } from '@rxap/ngx-status-check';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'eurogard-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  standalone: true,
  imports: [ RouterOutlet, StatusIndicatorComponent ],
})
export class AppComponent {

  constructor(
    @Inject(LOCALE_ID) localId: string,
    config: ConfigService<any>,
    titleService: Title,
    @Inject(KeycloakService)
    protected readonly keycloak: KeycloakService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    console.log('local', localId);
    if (config.get('app.name', null)) {
      titleService.setTitle(config.getOrThrow('app.name'));
    }
    this.matIconRegistry.addSvgIcon(
      'device-inbox-beta',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/device-inbox-beta.svg')
    );
  }

}
